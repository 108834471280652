<template>
  <div>
    <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template>
          <div v-for="item in items" :key="item.name">
            <v-list-group
              v-if="item.children"
              :key="item.name"
              v-model="item.model"
              :prepend-icon="item.model ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
              append-icon
            >
            
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <div v-for="(child, i) in item.children" :key="i">
                <router-link
                  class="sidebar-link"
                  :to="{ name: child.link}">
                  <v-list-item class="sidebar-link-item">
                    <!--<v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-action>-->
                    <v-list-item-content >
                      <v-list-item-title style="margin-left: 70px;">{{ child.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </div>
            </v-list-group>
            <v-divider></v-divider>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      items: [
          {
          },            
      ]
    };
  },
  methods: {},
  mounted() {
    
  }
};
</script>
<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(202, 206, 209) !important;
}

</style>