<template>
  <v-app id="inspire">
    <eps-sidebar></eps-sidebar>
      <eps-header :drawer="$store.state.drawer"></eps-header>

    <v-content>  
      <v-container fluid>
         <router-view>
           
         </router-view>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import adminSidebar from "./AdminSidebar";
import epsHeader from "../shared/Header"; 
export default {
  data: () => ({
    
  }),
  components: {
    'eps-sidebar': adminSidebar,
     'epsHeader': epsHeader,
  },
 
};
</script>


<style scoped>
</style>